<template>
  <div style="position: relative; background-color: white;" @drop="$emit('drop-on-row')" :key="forceUpdate">
    <div :class="[useLargeFont ? 'template-rows-7' : 'template-rows-6', 'mt-1']">
      <!-- draggable handler -->
      <section>
        <v-btn icon class="handle-drag-team" small v-if="!disable" style="width: 15px">
          <v-icon>mdi-drag-vertical</v-icon>
        </v-btn>
      </section>

      <!-- hour -->
      <section class="project-schedule">
        <v-select v-model="ora" :items="oras" :disabled="disable"
                  @change="val => $emit('on-time-change', val)"
                  height="25" solo hide-details class="ma-0 pa-0 sg-select">
        </v-select>
      </section>

      <!-- employee -->
      <section>
        <v-tooltip v-for="person in peopleData" :key="person.id" top color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-chip v-on="on" v-bind="attrs" x-small dark
                    :color="chipColor(person.type, person.id)"
                    :close="!disable"
                    @click:close="$emit('delete-person-change', {
                      type: person.type,
                      id: person.id,
                      people: peopleData
                    })"
                    @click="toggleForeman(person)"
                    :draggable="!isChipInComparedSchedule(person.type, person.id) && !disableChips"
                    @dragover="dragOver"
                    @dragstart="$emit('drag-chip', person)"
                    @dragend="dragEndChip"
                    style="cursor: grab; min-width: 125px;">
              <!-- foreman icon -->
              <v-icon v-if="foreman.id === person.id" color="yellow" size="18" class="pr-1">mdi-crown</v-icon>

              <p class="ma-0 pa-0 truncate-chip"
                 :style="foreman.id === person.id ? (useLargeFont ? 'width: 150px; font-size: 15px' : 'width: 70px') : (useLargeFont ? 'width: 172px; font-size: 15px' : 'width: 92px')">
                {{ person.name }}
              </p>
            </v-chip>
          </template>
          <span>{{ person.name }}</span>
        </v-tooltip>
        <v-progress-circular v-if="peopleLoading" indeterminate size="14" color="main"></v-progress-circular>
      </section>

      <!-- vehicle -->
      <section>
        <v-tooltip v-for="vehicle in vehiclesData" :key="vehicle.id" top color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-chip v-on="on" v-bind="attrs" x-small
                    :dark="chipColor('truck', vehicle.id) === 'red'"
                    :color="chipColor('truck', vehicle.id)"
                    :close="!disable"
                    @click:close="$emit('delete-vehicle-change', vehicle.id)"
                    :draggable="!isChipInComparedSchedule('vehicle', vehicle.id) && !disableChips"
                    @dragover="dragOver"
                    @dragstart="$emit('drag-chip', vehicle)"
                    @dragend="dragEndChip"
                    style="cursor: grab">
              <p class="ma-0 pa-0 truncate-chip" :style="useLargeFont ? 'font-size: 15px' : ''">{{ vehicle.code }}</p>
            </v-chip>
          </template>
          <span>{{ vehicle.label }}</span>
        </v-tooltip>
        <v-progress-circular v-if="vehicleLoading" indeterminate size="14" color="main"></v-progress-circular>
      </section>

      <!-- note -->
      <section class="project-schedule">
        <v-textarea v-model="noteData" :disabled="disable" hide-details rows="1" class="ma-0 pa-0" @change="val => $emit('note-change', val)"></v-textarea>
      </section>

      <!-- delete -->
      <section>
        <v-icon v-if="!disable" style="cursor: pointer" class="mt-2" @click="$emit('delete-row')" size="15" color="red">mdi-close</v-icon>
      </section>
    </div>

    <v-divider class="mt-1"></v-divider>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { militaryTime, translations } from '@/utils/common'
import updateForemanMutation from '@/graphql/UpdateForeman.gql'
import { getUseBiggerFontInSchedule } from '@/utils/api/config'

export default {
  name: 'ProjectScheduleTableRow',

  props: {
    id: {
      type: Number,
      default: () => Math.random() * 999999
    },
    time: {
      type: String,
      default: () => ''
    },
    people: {
      type: Array,
      default: () => []
    },
    vehicles: {
      type: Array,
      default: () => []
    },
    disable: {
      type: Boolean,
      default: () => false
    },
    peopleLoading: {
      type: Boolean,
      default: () => false
    },
    vehicleLoading: {
      type: Boolean,
      default: () => false
    },
    note: {
      type: String,
      default: () => ''
    },
    copyMode: {
      type: Boolean,
      default: () => false
    },
    foremanInput: {
      type: Object,
      default: () => {
      }
    }
  },

  data: () => ({
    peopleData: [],
    vehiclesData: [],
    ora: '',
    forceUpdate: 0,
    noteData: '',
    foreman: {}
  }),

  watch: {
    vehicles() {
      this.vehiclesData = Object.assign([], this.vehicles)
    },

    people() {
      this.peopleData = Object.assign([], this.people)
    },

    note() {
      this.noteData = this.note
    }
  },

  mounted() {
    this.vehiclesData = Object.assign([], this.vehicles)
    this.peopleData = Object.assign([], this.people)
    this.ora = this.time
    this.noteData = this.note
    this.foreman = Object.assign({}, this.foremanInput)
  },

  methods: {
    dragOver(e) {
      e.preventDefault()
    },

    dragEndChip() {
      localStorage.removeItem('dragged-item-list')
    },

    chipColor(type, id) {
      const TRUCK_COLOR = '#F1ECCE'
      const SUPPLIER_COLOR = '#E7BB41'
      const EMPLOYEE_COLOR = '#0197F6'
      const DISABLE_COLOR = 'red'
      const dateToCompare = JSON.parse(localStorage.getItem('dateToCompare'))
      if (!dateToCompare) {
        if (type === 'truck') return TRUCK_COLOR
        else if (type === 'employee') return EMPLOYEE_COLOR
        else if (type === 'supplier') return SUPPLIER_COLOR
      }
      if (dateToCompare?.dateCopy !== this.currentDate) {
        if (type === 'truck') return TRUCK_COLOR
        return type === 'supplier' ? SUPPLIER_COLOR : EMPLOYEE_COLOR
      }

      if (type === 'employee' && this.employeeIdUsed.includes(id)) {
        return DISABLE_COLOR
      }

      if (type === 'supplier' && this.supplierIdUsed.includes(id)) {
        return DISABLE_COLOR
      }

      if (type === 'truck' && this.vehicleIdUsed.includes(id)) {
        return DISABLE_COLOR
      }
      if (type === 'truck') return TRUCK_COLOR
      return type === 'supplier' ? SUPPLIER_COLOR : EMPLOYEE_COLOR
    },

    isChipInComparedSchedule(type, id) {
      // if (this.disableChips) return true;
      const dateToCompare = JSON.parse(localStorage.getItem('dateToCompare'))
      if (dateToCompare?.dateCopy !== this.currentDate) return false
      if (type === 'employee') {
        const employeeList = dateToCompare.employeeIds
        return employeeList.includes(id)
      } else if (type === 'supplier') {
        const supplierList = dateToCompare.supplierIds
        return supplierList.includes(id)
      } else if (type === 'vehicle') {
        const vehicleList = dateToCompare.vehicleIds
        return vehicleList.includes(id)
      }
    },

    // Function called when an employee is clicked, it will designate him/her as foreman.
    // If the selected employee is the foreman then the foreman is set to none.
    // Fires an update request to the backend
    async toggleForeman(person) {
      if (this.isMobileScreen() || this.currentDate < this.todaysDate || person.type !== 'employee' || this.disable) return

      let previousForeman = this.foreman // Used to reset the foreman if an error occurs

      // If clicking the current foreman then remove the foreman status
      if (person.id === this.foreman.id) {
        this.foreman = { id: null, name: null }
      } else {
        this.foreman = person
      }

      await this.$apollo
        .mutate({
          mutation: updateForemanMutation,
          variables: {
            teamId: this.id,
            foremanId: this.foreman.id
          }
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error('Error updating foreman: ', error)
          this.foreman = previousForeman
        })
    },

    isMobileScreen() {
      return window.matchMedia('only screen and (max-width: 600px)').matches
    }
  },

  computed: {
    ...mapState({
      todaysDate: state => state.date.todaysDate,
      todaysDateTime: state => state.date.todaysDateTime,
      currentDate: state => state.date.currentDate,
      currentDateTime: state => state.date.currentDateTime,
      employeeIdUsed: state => state.employees.employeeIdUsed,
      supplierIdUsed: state => state.suppliers.supplierIdUsed,
      vehicleIdUsed: state => state.vehicles.vehicleIdUsed
    }),
    oras: () => militaryTime,
    translations: () => translations,
    disableChips() {
      return (this.currentDate < this.todaysDate && !this.copyMode) || (!this.copyMode && this.disable) || this.isMobileScreen()
    },

    useLargeFont() {
      return getUseBiggerFontInSchedule()
    }
  }
}
</script>

<style scoped>
.project-schedule-table-row-label {
  color: #6e6e6e;
  font-size: 13px;
  font-weight: 500;
}

.template-rows-6 {
  display: grid;
  grid-template-columns: 15px 45px 130px 110px 100px 20px;
  width: 100%;
  grid-gap: 2px;
}

.template-rows-7 {
  display: grid;
  grid-template-columns: 15px 45px 195px 75px 70px 20px;
  width: 100%;
  grid-gap: 2px;
}

.card-style {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 4px;
}

@media only screen and (max-width: 600px) {
  /* used to check screen size */
}
</style>
