import apolloUtil from '@/service/ApolloUtil'

import updateProgramProjectSupervisor from '@/service/programProject/UpdateProgramProjectSupervisor.gql'

class ProgramProjectService {

  updateSupervisor(params) {
    return apolloUtil.mutate(updateProgramProjectSupervisor, params)
  }

}

export default new ProgramProjectService()
