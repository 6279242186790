<template>
  <div class="d-flex justify-center align-center">
    <v-btn @click="prevDay" depressed fab color="main" x-small :disabled="disable">
      <v-icon color="white">mdi-arrow-left-bold</v-icon>
    </v-btn>

    <v-menu v-model="menu" transition="scale-transition">
      <template v-slot:activator="{ on }">
        <v-btn x-large v-on="on" color="main" text class="ma-1" style="width: 190px">
          <v-icon size="25" color="main" class="mr-1">mdi-calendar-month</v-icon>
          {{ date }}
        </v-btn>
      </template>
      <v-date-picker v-model="picker" @change="onDateChange" locale="it" :disabled="disable"></v-date-picker>
    </v-menu>

    <v-btn @click="nextDay" fab color="main" depressed x-small :disabled="disable">
      <v-icon color="white">mdi-arrow-right-bold</v-icon>
    </v-btn>
  </div>
</template>


<script>
import { MomentMixin } from '@/utils/mixins/MomentMixin.js'

export default {
  name: 'DateHeader',

  props: {
    value: {
      type: String,
      default: () => this.getSimpleDay().substr(0, 10)
    },
    disable: {
      type: Boolean,
      default: () => false
    }
  },

  data: () => ({
    currentDate: new Date(),
    picker: '',
    displayPicker: false,
    menu: false
  }),

  created() {
    this.picker = this.value
    this.currentDate = this.momentToDate(this.picker)
  },

  mixins: [MomentMixin],

  watch: {
    value() {
      this.picker = this.value
      this.currentDate = this.momentToDate(this.picker)
    }
  },

  methods: {
    nextDay() {
      const nextDay = this.currentDate.setDate(this.currentDate.getDate() + 1)
      this.currentDate = this.momentToDate(nextDay)
      this.picker = this.simpleFormat(this.currentDate).substr(0, 10)
      this.$emit('on-date-change', {
        date: this.formatDate(this.currentDate),
        dateTime: this.currentDate.getTime()
      })
    },

    prevDay() {
      const prevDay = this.currentDate.setDate(this.currentDate.getDate() - 1)
      this.currentDate = this.momentToDate(prevDay)
      this.picker = this.simpleFormat(this.currentDate).substr(0, 10)
      this.$emit('on-date-change', {
        date: this.formatDate(this.currentDate),
        dateTime: this.currentDate.getTime()
      })
    },

    onDateChange(date) {
      this.menu = false
      const convertedDate = this.momentToDate(date)
      this.currentDate = convertedDate
      this.$emit('on-date-change', {
        date: this.formatDate(this.currentDate),
        dateTime: convertedDate.getTime()
      })
    }
  },

  computed: {
    date() {
      if (this.value) {
        const [year, month, day] = this.value.split('-')
        return `${day}/${month}/${year}`
      }
      return this.formatDate(this.currentDate)
    }
  }
}
</script>
