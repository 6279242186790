export default class Schedule {

  constructor(id, orderNumber, project, teams, canAccess) {
    const defaultId = null

    const defaultProject = {
      id: '',
      names: [],
      site: '',
      classTransfer: '',
      conGlomerateDesc: '',
      quintalsExpectedDesc: '',
      client: '',
      projects: [{
        name: '',
        part: null,
        main: true,
        projectFilter: '',
        searchedProjects: [],
        projectSearchLoader: false,
        supervisor: ''
      }]
    }

    this.id = id || defaultId
    this.project = project || defaultProject
    this.teams = teams || []
    this.orderNumber = orderNumber
    this.canAccess = canAccess
  }

  getSchedule() {
    return {
      id: this.id,
      project: this.project,
      teams: this.teams,
      orderNumber: this.orderNumber,
      canAccess: this.canAccess
    }
  }
}
