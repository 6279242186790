<template>
  <div>
    <!-- button to trigger the dialog open -->
    <v-btn @click="displayDialog = true" :title="translations.exportReport" color="main" icon dark>
      <v-icon size="28">mdi-file-export-outline</v-icon>
    </v-btn>

    <!-- dialog -->
    <v-dialog :value="displayDialog" @click:outside="displayDialog = false" @keydown.esc="displayDialog = false" max-width="750px">
      <v-card class="pa-6">
        <!-- title -->
        <v-card-title class="d-flex justify-space-between text-h5 main--text pt-0 pb-4">
          <div></div>
          <div>{{ translations.exportReport }}</div>
          <v-icon @click="displayDialog = false" color="main">mdi-close</v-icon>
        </v-card-title>

        <!-- content -->
        <v-card-text class="px-0 py-4 d-flex justify-space-around">
          <!-- date -->
          <div>
            <template v-if="reportSelected.oneDate">
              <p class="ma-0 pa-3 v-picker__title primary" style="font-size: 16px">
                <b>{{ translations.date }}</b> {{ date }}
              </p>
              <v-date-picker @click:date="adjustDates" v-model="date"
                             show-current="false" show-adjacent-months locale="it" no-title elevation="1">
              </v-date-picker>
            </template>

            <template v-else>
              <p class="ma-0 pa-3 v-picker__title primary" style="font-size: 16px">
                <b>{{ translations.date }} {{ translations.from }}</b> {{ dates[0] }} <b>{{ translations.to }}</b> {{ dates[1] ? dates[1] : '?' }}
              </p>
              <v-date-picker @click:date="adjustDates" v-model="dates" range
                             show-current="false" show-adjacent-months locale="it" no-title elevation="1">
              </v-date-picker>
            </template>
          </div>

          <!-- report list -->
          <v-card class="py-0 my-0" style="width: 45%">
            <p class="ma-0 pa-3 v-picker__title primary" style="font-size: 16px">
              <b>{{ translations.report }}</b>
            </p>
            <v-radio-group v-model="reportSelected" class="px-4 py-0" hide-details>
              <v-radio v-for="report in reports" :key="report.name" :value="report"
                       @click="selectReport(report)"
                       class="mb-5" dense hide-details>
                <template v-slot:label>
                  <div class="d-flex justify-space-between align-center" style="width: 100%">
                    <div>
                      <v-icon size="28" color="main" class="pr-2">{{ report.icon }}</v-icon>
                      <span style="font-size: 16px">{{ translations['report' + report.name] }}</span>
                    </div>

                    <!-- export report to disk -->
                    <v-btn v-if="report.diskPath"
                           @click="generateReportToDisk(report)" :title="translations.writeToDiskReport"
                           color="main" icon>
                      <v-icon>mdi-file-import-outline</v-icon>
                    </v-btn>
                  </div>
                </template>
              </v-radio>
            </v-radio-group>
          </v-card>
        </v-card-text>

        <!-- actions -->
        <v-card-actions class="d-flex justify-center">
          <v-col class="py-0" style="max-width: 120px">
            <v-select v-model="modeSelected" :items="modeAvailable" :placeholder="translations.select" outlined dense hide-details></v-select>
          </v-col>

          <v-btn @click="generateReport" :disabled="disableBtn" color="main" class="white--text px-8">{{ translations.generateReport }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import moment from 'moment'
import { translations } from '@/utils/common'

import { generateReport } from '@/utils/api/reportsApi'

const DEFAULT_MODE_AVAILABLE = ['csv', 'xls', 'pdf']

export default {
  name: 'ReportDialog',

  props: {
    reports: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      translations: translations,

      displayDialog: false,

      date: null,
      dates: [],
      reportSelected: {},
      modeAvailable: null,
      modeSelected: null
    }
  },

  mounted() {
    let yesterday = moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD')
    this.date = yesterday
    this.dates = [yesterday, yesterday]
    this.selectReport(this.reports[0])
  },

  computed: {
    disableBtn() {
      return !this.reportSelected.oneDate && this.dates.length !== 2
    }
  },

  methods: {
    selectReport(report) {
      this.reportSelected = report
      this.modeAvailable = report.modeAvailable ? report.modeAvailable : DEFAULT_MODE_AVAILABLE
      if (!this.modeAvailable.includes(this.modeSelected)) {
        this.modeSelected = this.modeAvailable[0]
      }
      this.adjustDates(this.date)
    },

    adjustDates(date) {
      if (this.reportSelected.oneDate) {
        this.dates = [ date, date ]
      } else {
        if (this.dates.length === 2 && this.dates[0] > this.dates[1]) {
          let tmp = this.dates[0]
          this.dates[0] = this.dates[1]
          this.dates[1] = tmp
        }
        this.date = date
      }
    },

    _prepareParams(report) {
      return {
        title: report.name,
        mode: this.modeSelected,
        dateFrom: report.oneDate ? this.date : this.dates[0],
        dateTo: report.oneDate ? null : this.dates[1],
        ...report.params
      }
    },

    generateReport() {
      generateReport(this._prepareParams(this.reportSelected))
    },

    generateReportToDisk(report) {
      let params = this._prepareParams(this.reportSelected)
      params.mode = 'xls'
      params.diskPath = report.diskPath
      generateReport(params)
    }
  }
}
</script>
