var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:_vm.forceUpdate,staticStyle:{"position":"relative","background-color":"white"},on:{"drop":function($event){return _vm.$emit('drop-on-row')}}},[_c('div',{class:[_vm.useLargeFont ? 'template-rows-7' : 'template-rows-6', 'mt-1']},[_c('section',[(!_vm.disable)?_c('v-btn',{staticClass:"handle-drag-team",staticStyle:{"width":"15px"},attrs:{"icon":"","small":""}},[_c('v-icon',[_vm._v("mdi-drag-vertical")])],1):_vm._e()],1),_c('section',{staticClass:"project-schedule"},[_c('v-select',{staticClass:"ma-0 pa-0 sg-select",attrs:{"items":_vm.oras,"disabled":_vm.disable,"height":"25","solo":"","hide-details":""},on:{"change":function (val) { return _vm.$emit('on-time-change', val); }},model:{value:(_vm.ora),callback:function ($$v) {_vm.ora=$$v},expression:"ora"}})],1),_c('section',[_vm._l((_vm.peopleData),function(person){return _c('v-tooltip',{key:person.id,attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticStyle:{"cursor":"grab","min-width":"125px"},attrs:{"x-small":"","dark":"","color":_vm.chipColor(person.type, person.id),"close":!_vm.disable,"draggable":!_vm.isChipInComparedSchedule(person.type, person.id) && !_vm.disableChips},on:{"click:close":function($event){return _vm.$emit('delete-person-change', {
                    type: person.type,
                    id: person.id,
                    people: _vm.peopleData
                  })},"click":function($event){return _vm.toggleForeman(person)},"dragover":_vm.dragOver,"dragstart":function($event){return _vm.$emit('drag-chip', person)},"dragend":_vm.dragEndChip}},'v-chip',attrs,false),on),[(_vm.foreman.id === person.id)?_c('v-icon',{staticClass:"pr-1",attrs:{"color":"yellow","size":"18"}},[_vm._v("mdi-crown")]):_vm._e(),_c('p',{staticClass:"ma-0 pa-0 truncate-chip",style:(_vm.foreman.id === person.id ? (_vm.useLargeFont ? 'width: 150px; font-size: 15px' : 'width: 70px') : (_vm.useLargeFont ? 'width: 172px; font-size: 15px' : 'width: 92px'))},[_vm._v(" "+_vm._s(person.name)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(person.name))])])}),(_vm.peopleLoading)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":"14","color":"main"}}):_vm._e()],2),_c('section',[_vm._l((_vm.vehiclesData),function(vehicle){return _c('v-tooltip',{key:vehicle.id,attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticStyle:{"cursor":"grab"},attrs:{"x-small":"","dark":_vm.chipColor('truck', vehicle.id) === 'red',"color":_vm.chipColor('truck', vehicle.id),"close":!_vm.disable,"draggable":!_vm.isChipInComparedSchedule('vehicle', vehicle.id) && !_vm.disableChips},on:{"click:close":function($event){return _vm.$emit('delete-vehicle-change', vehicle.id)},"dragover":_vm.dragOver,"dragstart":function($event){return _vm.$emit('drag-chip', vehicle)},"dragend":_vm.dragEndChip}},'v-chip',attrs,false),on),[_c('p',{staticClass:"ma-0 pa-0 truncate-chip",style:(_vm.useLargeFont ? 'font-size: 15px' : '')},[_vm._v(_vm._s(vehicle.code))])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(vehicle.label))])])}),(_vm.vehicleLoading)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":"14","color":"main"}}):_vm._e()],2),_c('section',{staticClass:"project-schedule"},[_c('v-textarea',{staticClass:"ma-0 pa-0",attrs:{"disabled":_vm.disable,"hide-details":"","rows":"1"},on:{"change":function (val) { return _vm.$emit('note-change', val); }},model:{value:(_vm.noteData),callback:function ($$v) {_vm.noteData=$$v},expression:"noteData"}})],1),_c('section',[(!_vm.disable)?_c('v-icon',{staticClass:"mt-2",staticStyle:{"cursor":"pointer"},attrs:{"size":"15","color":"red"},on:{"click":function($event){return _vm.$emit('delete-row')}}},[_vm._v("mdi-close")]):_vm._e()],1)]),_c('v-divider',{staticClass:"mt-1"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }