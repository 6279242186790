<template>
  <v-card class="ma-2 pa-3 tom-card" style="cursor:default">
    <h3 class="main--text pb-1">{{ item.title }}</h3>

    <div v-for="vehicleActivity in item.vehicleActivities" :key="vehicleActivity.id" class="d-flex justify-space-between align-center">
      <p class="my-1">
        <v-tooltip top color="black" :disabled="!vehicleActivity.note">
          <template v-slot:activator="{ on, attrs }">
            <div v-on="on" v-bind="attrs" style="cursor:default">
              {{ vehicleActivity.vehicle.code }} {{ vehicleActivity.vehicle.label }}
              <span v-if="vehicleActivity.note && vehicleActivity.note.length > 0">*</span>
            </div>
          </template>
          {{ vehicleActivity.note }}
        </v-tooltip>
      </p>
    </div>
  </v-card>
</template>


<script>
export default {
  name: 'VehicleCard',

  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>


<style>
.tom-card {
  font-size: 12px;
}
</style>
