<template>
  <v-dialog :value="value" @input="val => $emit('input', val)" persistent max-width="750px">
    <v-card>
      <v-card-text>
        <v-container>
          <section class="d-flex justify-space-between">
            <aside>
              <p class="ma-0 pa-2 f15">
                <b>{{ translations.dateToCopy }}</b>
              </p>
              <v-date-picker show-current="false" v-model="dateToCopy" locale="it"></v-date-picker>
            </aside>

            <aside>
              <p class="ma-0 pa-2 f15">
                <b>{{ translations.dateToProgram }}</b>
              </p>
              <v-date-picker show-current="false" v-model="dateToSchedule" locale="it"></v-date-picker>
            </aside>
          </section>
        </v-container>
      </v-card-text>
      <v-card-actions style="border-top: 1px solid rgba(125, 125, 125, 0.2)">
        <v-btn color="red darken-1" text @click="$emit('input', false)">
          {{ translations.close }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1 white--text" @click="submit" :disabled="submitDisabled">
          {{ translations.submit }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { MomentMixin } from "@/utils/mixins/MomentMixin.js";
import { translations } from "@/utils/common";

export default {
  props: {
    value: {
      type: Boolean,
      default: () => false
    }
  },

  data: () => ({
    dateToCopy: null,
    dateToSchedule: null,
    submitDisabled: false
  }),

  created() {
    this.dateToCopy = this.getSubtractedDate(1, "days").substr(0, 10);
    this.dateToSchedule = this.todaysDate;
  },

  mixins: [MomentMixin],

  methods: {
    submit() {
      const [year, month, day] = this.dateToSchedule.split("-");
      const openDateString = `${month}-${day}-${year}`;
      window.open(`${window.location.origin}/schedule?date=${openDateString}`);
      const dateToCompare = {
        dateCopy: this.dateToCopy,
        date: this.dateToSchedule,
        employeeIds: [],
        supplierIds: [],
        vehicleIds: []
      };
      localStorage.setItem("dateToCompare", JSON.stringify(dateToCompare));
      this.$emit("submit", {
        date: this.dateToCopy,
        dateTime: this.momentToDateGetTime(this.dateToCopy)
      });
      this.$emit("input", false);
    },

    submitDisabledCheck() {
      this.submitDisabled = this.dateToCopy >= this.dateToSchedule;
    }
  },

  computed: {
    ...mapState({
      todaysDate: state => state.date.todaysDate
    }),

    translations: () => translations
  },

  watch: {
    dateToCopy() {
      this.submitDisabledCheck();
    },

    dateToSchedule() {
      this.submitDisabledCheck();
    }
  }
};
</script>

<style scoped>
.f15 {
  font-size: 15px;
}
</style>
