<template>
  <div class="list-style">
    <!-- search -->
    <div style="margin: 0 auto; width: 93%;">
      <v-text-field v-model="name" class="my-2" solo hide-details :placeholder="translations.search"></v-text-field>
    </div>

    <!-- items -->
    <div class="scroll-bar scrollable">
      <div v-for="item in orderedData" :key="item.id"
           :class="['d-flex', 'align-center', 'justify-space-between', 'pa-2', isIdFound(item) ? 'no-selection' : 'hover-style']"
           style="margin: 5px;"
           :draggable="!isIdFound(item)"
           @dragstart="$emit('drag-item', item)">
        <strong class="ma-0 pa-0" :class="`${isIdFound(item) ? 'font-italic text-decoration-line-through unusedItems--text' : null}`">
          {{ item.name }}
        </strong>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { translations } from '@/utils/common'
import { getPlannerResourcesSelectedOnBottom } from '@/utils/api/config.js'

export default {
  name: 'List',

  data() {
    return {
      translations: translations,
      name: ''
    }
  },

  props: {
    data: {
      type: Array,
      default: () => []
    },

    type: {
      type: String,
      default: () => ''
    }
  },

  watch: {
    name() {
      this.$emit('search', this.name)
    }
  },

  methods: {
    isIdFound(item) {
      if (this.type === 'employee') {
        return this.employeeIdUsed.includes(item.id)
      } else if (this.type === 'supplier') {
        return this.supplierIdUsed.includes(item.id)
      } else if (this.type === 'truck') {
        return this.vehicleIdUsed.includes(item.id)
      }
    }
  },

  computed: {
    ...mapState({
      employeeIdUsed: state => state.employees.employeeIdUsed,
      supplierIdUsed: state => state.suppliers.supplierIdUsed,
      vehicleIdUsed: state => state.vehicles.vehicleIdUsed
    }),

    // this computed property orders the items based on a config property
    orderedData() {
      if (getPlannerResourcesSelectedOnBottom()) {
        const usedItems = this.data.filter(item => this.isIdFound(item))
        const availableItems = this.data.filter(item => !this.isIdFound(item))
        return [...availableItems, ...usedItems]
      } else {
        return this.data
      }
    }
  }
}
</script>

<style scoped>
.list-style {
  font-size: smaller;
  height: 400px;
  overflow: hidden;
  box-shadow: none !important;
}

.scrollable {
  height: 335px;
  overflow: auto;
}

.scroll-bar::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  border-radius: 5px;
}

.scroll-bar::-webkit-scrollbar-track {
  background: #f6f8fa;
  border-radius: 5px;
}

.scroll-bar::-webkit-scrollbar-thumb {
  background: #d0cfcf;
  border-radius: 5px;
}

.scroll-bar::-webkit-scrollbar-thumb:hover {
  background: #888;
  border-radius: 5px;
  cursor: grab !important;
}

.no-selection {
  cursor: default;
}

.hover-style {
  transition: 0.4s;
  cursor: grab;
}

.hover-style:hover {
  background: #d9d9d9;
}
</style>
