import apolloUtil from '@/service/ApolloUtil'

import cloneAllDailyPrograms from '@/service/program/CloneAllDailyPrograms.gql'

class ProgramService {

  clonePrograms(params) {
    return apolloUtil.mutate(cloneAllDailyPrograms, params)
  }

}

export default new ProgramService()
