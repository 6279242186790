<template>
  <div :key="`table-container-${teamType}-${programId}`">
    <div class="d-flex align-center justify-space-between pa-1 mb-2"
         style="background-image: linear-gradient(to left, rgba(16, 66, 114, 0.05), rgba(16, 66, 114, 0.01)); border-radius: 4px">
      <h3 class="ma-0 pa-1">{{ title }}</h3>

      <v-btn v-if="!disable" @click="addNewRow" x-small dark fab depressed class="main">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>

    <div :ref="`table${randomIdSignature}`"
         :class="[fixedProjectScheduleHeight ? 'fixed-table-height' : 'dynamic-table-height', 'scroll-bar', dropOverCounter === 0 ? 'overflow-table' : 'overflow-hidden']"
         @drop="dropOnTable"
         @dragenter="dragEnterTable"
         @dragleave="dragLeaveTable"
         @dragstart="dragStartTable"
         @dragover="dragOver">
      <draggable handle=".handle-drag-team"
                 v-model="clients"
                 :key="forceUpdateDraggable"
                 :disabled="!draggableRow"
                 @change="changeRowOrder">
        <div v-for="(team, index) in clients" :key="`${team.id}_${programId}`"
             :draggable="draggableRow"
             @dragover="dragOver"
             @dragstart="dragRow(team)"
             @dragend="dragRowEnd"
             @drop="dropOnRow(team)"
             :data-id="index"
             class="table-row">
          <project-schedule-table-row :key="`row_${team.id}_${programId}`"
                                      :id="team.id"
                                      :copyMode="copyMode"
                                      :disable="disable"
                                      :foremanInput="team.foremanEmployee"
                                      :note="team.note"
                                      :people="team.people"
                                      :peopleLoading="team.peopleLoading"
                                      :time="team.ora"
                                      :vehicleLoading="team.vehicleLoading"
                                      :vehicles="team.trucks"
                                      @dragover="dragOver"
                                      @on-time-change="val => sortTime(team, val)"
                                      @delete-row="deleteRow(team.id)"
                                      @delete-person-change="val => removePersonFromList(val.type, val.id, val.people, team)"
                                      @delete-vehicle-change="val => removeVehicleFromList(val, team)"
                                      @note-change="val => onNoteChange(team, val)"
                                      @drag-chip="dragChip"
                                      class="item">
          </project-schedule-table-row>
        </div>
      </draggable>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import moment from 'moment'
import { MomentMixin } from '@/utils/mixins/MomentMixin.js'
import { eventBus } from '@/utils/common'

import saveTeamQuery from '@/graphql/SaveTeam.gql'
import deleteTeamMutation from '@/graphql/DeleteTeam.gql'
import addOrRemoveEmployeeMutation from '@/graphql/AddOrRemoveEmployee.gql'
import addOrRemoveVehicleMutation from '@/graphql/AddOrRemoveVehicle.gql'
import addOrRemoveSupplierMutation from '@/graphql/AddOrRemoveSupplier.gql'
import updateTeamTimeMutation from '@/graphql/UpdateTeamTime.gql'
import updateTeamNoteMutation from '@/graphql/UpdateTeamNote.gql'
import changeTeamsOrderMutation from '@/graphql/ChangeTeamsOrder.gql'

import ProjectScheduleTableRow from './ProjectScheduleTableRow'
import draggable from 'vuedraggable'

import { getFixedProjectScheduleHeight } from '@/utils/api/config.js'

export default {
  name: 'ProjectScheduleTable',

  mixins: [MomentMixin],

  components: {
    ProjectScheduleTableRow,
    draggable
  },

  props: {
    title: {
      type: String,
      default: () => ''
    },

    data: {
      type: Array,
      default: () => []
    },

    disable: {
      type: Boolean,
      default: () => false
    },

    scheduleType: {
      type: Number,
      default: () => 1
    },

    programId: {
      type: Number,
      default: () => 0
    },

    copyMode: {
      type: Boolean,
      default: () => false
    },

    teamType:{
      type: Number
    }
  },

  data: () => ({
    clients: [],
    tableCounter: 0,
    randomIdSignature: Math.random() * 10000,
    forceUpdate: 0,
    dropOverCounter: 0,
    dateToCompare: JSON.parse(localStorage.getItem('dateToCompare')),
    forceUpdateDraggable: 0,
    indexToInsertInToTable: null
  }),

  created() {
    this.init()
    this.eventListeners()
  },

  beforeDestroy() {
    eventBus.$off('drop', this.dropHandler)
  },

  methods: {
    ...mapActions([
      'insertIntoEmployeeIdUsed',
      'insertIntoSupplierIdUsed',
      'insertIntoVehicleIdUsed',
      'removeSupplierIdUsed',
      'removeEmployeeIdUsed',
      'removeVehicleIdUsed',
      'removeEmployeeIdUsedMultiple',
      'removeSupplierIdUsedMultiple',
      'removeVehicleIdUsedMultiple'
    ]),

    init() {
      this.clients = Object.assign(
        [],
        this.data.filter(({ teamType }) => teamType === this.scheduleType)
      )

      // Copy of client but with aditional properties such as loaders
      this.clients = this.clients.map(client => ({
        ...client,
        peopleLoading: false,
        vehicleLoading: false
      }))
      // .sort((a, b) => (a.ora > b.ora ? 1 : -1));
    },

    eventListeners() {
      eventBus.$on('row-drop-on-same-schedule', async data => {
        const date = moment(this.currentDate).format('YYYY-MM-DD')
        /* 
          This conditional makes sure that the row that is being dropped
          does not delete the row unless it is same scheule on current day
        */
        if (date === data.programData.date && `${data.programData.programId}_${data.programData.scheduleType}` === `${this.programId}_${this.scheduleType}`) {
          await this.deleteRow(data.programData.teamId, true)
        }
      })

      // FIXME do we really need the eventBus?
      // This event is used to listen for a row being dropped and making sure that
      // the row that is being dropped is not on the same schedule. Once its dropped
      // we delete the ids to make sure it is not being copied over
      eventBus.$on('drop', this.dropHandler)
    },

    async dropHandler(data){
      const date = moment(this.currentDate).format('YYYY-MM-DD')
      const allowedMovables = ['employee', 'truck']
      if (date === data.date) {
        for (const client of this.clients) {
          if (`${data.programId}${data.scheduleType}${data.teamId}` !== `${this.programId}${this.scheduleType}${client.id}` && allowedMovables.includes(data.type)) {
            if (data.type === 'employee') {
              const personToDelete = client.people.find(({ id, type }) => id === data.id && data.type === type)

              if (1 === 0 && personToDelete) {
                await this.$apollo
                  .mutate({
                    mutation: addOrRemoveEmployeeMutation,
                    variables: {
                      teamId: client.id,
                      employeeId: data.id
                    }
                  })
                  .catch(error => {
                    this.handleError(error)
                  })
              }
            } else if (data.type === 'truck') {
              const truckToDelete = client.trucks.find(({ id }) => id === data.id)
              if (1 === 0 && truckToDelete) {
                await this.$apollo
                  .mutate({
                    mutation: addOrRemoveVehicleMutation,
                    variables: {
                      teamId: client.id,
                      vehicleId: data.id
                    }
                  })
                  .catch(error => {
                    this.handleError(error)
                  })
              }
            }
          }
        }

        this.clients = this.clients.map(client => {
          const notEqualToSameRow = `${data.programId}${data.scheduleType}${data.teamId}` !== `${this.programId}${this.scheduleType}${client.id}`

          if (data.type === 'employee' && notEqualToSameRow) {
            const people = client.people.filter(person => (person.id !== data.id && person.type === data.type) || person.type === 'supplier')

            return { ...client, people }
          } else if (data.type === 'truck' && notEqualToSameRow) {
            const trucks = client.trucks.filter(truck => truck.id !== data.id)
            return { ...client, trucks }
          } else return client
        })
      }
    },

    // When the row order changes we update the rows.
    async changeRowOrder() {
      await this.$apollo
        .mutate({
          mutation: changeTeamsOrderMutation,
          variables: {
            teamIds: this.clients.map(({ id }) => id)
          }
        })
        .catch(error => {
          this.handleError(error)
        })

      this.forceUpdateDraggable++
    },

    /**
     On drag we set the localstorage item "dragged-item-list" to get extract all the data
     in the row
     */
    dragRow(row) {
      if (!localStorage.getItem('dragged-item-list')) {
        const employees = row.people.filter(({ type, disabled }) => type === 'employee' && !disabled)
        const stringify = JSON.stringify({
          programData: {
            teamId: row.id,
            programId: this.programId,
            scheduleType: this.scheduleType,
            date: moment(this.currentDate).format('YYYY-MM-DD')
          },
          data: {
            id: 254,
            ora: '',
            people: employees,
            peopleLoading: false,
            trucks: row.trucks,
            vehicleLoading: false
          }
        })
        localStorage.setItem('dragged-item-row', stringify)
      }
    },

    // When a row is done dragging we make sure to clear out the localStorage
    dragRowEnd() {
      localStorage.removeItem('dragged-item-row')
    },

    async sortTime(item, time) {
      await this.$apollo
        .mutate({
          mutation: updateTeamTimeMutation,
          variables: {
            teamId: item.id,
            time: time
          }
        })
        .then(() => {
          item.ora = time
          this.$emit('on-change', this.clients)
        })
        .catch(error => {
          this.handleError(error)
        })
    },

    // This function is used for finding the position of where to place the row on the table when there is multiple rows
    getDragAfterElement(container, y) {
      const draggableElements = [...container.querySelectorAll('.table-row')]
      return draggableElements.reduce(
        (closest, child) => {
          const box = child.getBoundingClientRect()
          const offset = y - box.top - box.height / 2
          if (offset < 0 && offset > closest.offset) {
            return { offset, element: child }
          } else {
            return closest
          }
        },
        { offset: Number.NEGATIVE_INFINITY }
      ).element
    },

    // Watches for note changes
    async onNoteChange(row, note) {
      row.note = note
      await this.$apollo
        .mutate({
          mutation: updateTeamNoteMutation,
          variables: {
            teamId: row.id,
            note: note
          }
        })
        .catch(error => {
          this.handleError(error)
        })
    },

    // Handles the deletion of employee/supplier off of a row
    async removePersonFromList(type, id, people, row) {
      // remove employee
      if (type === 'employee') {
        await this.$apollo.mutate({
          mutation: addOrRemoveEmployeeMutation,
          variables: {
            teamId: row.id,
            employeeId: id
          }
        }).catch(error => this.handleError(error))

        this.removeEmployeeIdUsed(id)
        if (this.dateToCompare?.date === this.currentDate) {
          this.reassignLocalStorageIdItems()
        }
      }

      // remove supplier
      else {
        await this.$apollo.mutate({
          mutation: addOrRemoveSupplierMutation,
          variables: {
            teamId: row.id,
            supplierId: id
          }
        }).catch(error => this.handleError(error))

        this.removeSupplierIdUsed(id)
        if (this.dateToCompare?.date === this.currentDate) {
          this.reassignLocalStorageIdItems()
        }
      }

      this.$emit('on-change', this.clients)

      const employeeToBeRemoved = row.people.find(e => e.id === id)
      const index = row.people.indexOf(employeeToBeRemoved)
      row.people.splice(index, 1)
    },

    // Removes a vehicle off of the list
    async removeVehicleFromList(id, row) {
      await this.$apollo.mutate({
        mutation: addOrRemoveVehicleMutation,
        variables: {
          teamId: row.id,
          vehicleId: id
        }
      }).catch(error => this.handleError(error))

      this.removeVehicleIdUsed(id)
      if (this.dateToCompare?.date === this.currentDate) {
        this.reassignLocalStorageIdItems()
      }

      const vehicleToBeRemoved = row.trucks.find(v => v.id === id)
      const index = row.trucks.indexOf(vehicleToBeRemoved)
      row.trucks.splice(index, 1)

      this.$emit('on-change', this.clients)
    },

    addEmployeeToList(id) {
      this.insertIntoEmployeeIdUsed(id)
      if (this.dateToCompare?.date === this.currentDate) {
        this.reassignLocalStorageIdItems()
      }
    },

    addVehicleToList(id) {
      this.insertIntoVehicleIdUsed(id)
      if (this.dateToCompare?.date === this.currentDate) {
        this.reassignLocalStorageIdItems()
      }
    },

    addSupplierToList(id) {
      this.insertIntoSupplierIdUsed(id)
      if (this.dateToCompare?.date === this.currentDate) {
        this.reassignLocalStorageIdItems()
      }
    },

    // Updates localstorage of the new items
    reassignLocalStorageIdItems() {
      this.dateToCompare.vehicleIds = this.vehicleIdUsed
      this.dateToCompare.supplierIds = this.supplierIdUsed
      this.dateToCompare.employeeIds = this.employeeIdUsed
      localStorage.setItem('dateToCompare', JSON.stringify(this.dateToCompare))
    },

    async addNewRow() {
      let orderNumber = 0

      if (this.clients.length > 0) {
        const orderNumsArray = this.clients.map(c => c.orderNumber)
        orderNumber = Math.max.apply(null, orderNumsArray) + 1
      }

      const team = {
        id: null,
        dateStr: this.formatDate(this.currentDate),
        type: this.scheduleType,
        orderNumber: orderNumber,
        program: { id: this.programId },
        time: ''
      }

      const data = await this.$apollo
        .mutate({
          mutation: saveTeamQuery,
          variables: { team }
        })
        .catch(error => {
          this.handleError(error)
        })

      this.clients.push({
        id: data.data.saveTeam.id,
        ora: '',
        trucks: [],
        people: [],
        orderNumber: orderNumber
      })
    },

    // We delete the row and makes sure to clear the vuex store and localstorage
    // to enable available employees and vehicles
    async deleteRow(id, shouldNotRemoveIds) {
      const index = this.clients.findIndex(a => a.id === id)
      try {
        await this.$apollo
          .mutate({
            mutation: deleteTeamMutation,
            variables: { teamId: id }
          })
          .catch(error => {
            this.handleError(error)
          })

        const employeeIds = []
        const supplierIds = []
        this.clients[index].people.forEach(({ id, type }) => {
          if (type === 'employee') employeeIds.push(id)
          else if (type === 'supplier') supplierIds.push(id)
        })
        const vehicleIds = this.clients[index].trucks.map(({ id }) => id)

        if (shouldNotRemoveIds) {
          this.clients.splice(index, 1)
          return
        }

        this.removeEmployeeIdUsedMultiple(employeeIds)
        this.removeSupplierIdUsedMultiple(supplierIds)
        this.removeVehicleIdUsedMultiple(vehicleIds)

        if (this.dateToCompare?.date === this.currentDate) {
          this.reassignLocalStorageIdItems()
        }
        this.clients.splice(index, 1)
      } catch (e) {
        this.clients.splice(index, 1)
      }
    },

    dragChip(item) {
      localStorage.setItem('dragged-item-list', JSON.stringify(item))
    },

    dragOver(e) {
      e.preventDefault()
    },

    async dropOnRow(row) {
      if (this.disable) return

      // retrieve the dragged item
      const draggedItem = localStorage.getItem('dragged-item-list')
      if (!draggedItem) return
      const item = JSON.parse(draggedItem)
      if (item.disabled) return

      if (item.type === 'employee') {
        const doesEmployeeExist = row.people.findIndex(a => a.id === item.id && a.type === 'employee')
        if (doesEmployeeExist < 0) {
          row.peopleLoading = true

          await this.$apollo.mutate({
            mutation: addOrRemoveEmployeeMutation,
            variables: {
              teamId: row.id,
              employeeId: item.id
            }
          }).catch(error => this.handleError(error))

          row.peopleLoading = false
          row.people.push(item)

          this.addEmployeeToList(item.id)

          eventBus.drop({
            ...item,
            date: moment(this.currentDate).format('YYYY-MM-DD'),
            programId: this.programId,
            teamId: row.id,
            scheduleType: this.scheduleType
          })
        }
      } else if (item.type === 'truck') {
        const doesTruckExist = row.trucks.findIndex(a => a.id === item.id)
        if (doesTruckExist < 0) {
          row.vehicleLoading = true

          await this.$apollo.mutate({
            mutation: addOrRemoveVehicleMutation,
            variables: {
              teamId: row.id,
              vehicleId: item.id
            }
          }).catch(error => this.handleError(error))

          row.vehicleLoading = false
          row.trucks.push(item)

          this.addVehicleToList(item.id)

          eventBus.drop({
            ...item,
            date: moment(this.currentDate).format('YYYY-MM-DD'),
            programId: this.programId,
            teamId: row.id,
            scheduleType: this.scheduleType
          })
        }
      } else if (item.type === 'supplier') {
        const doesSupplierExist = row.people.findIndex(a => a.id === item.id && a.type === 'supplier')
        if (doesSupplierExist < 0) {
          row.peopleLoading = true

          await this.$apollo.mutate({
            mutation: addOrRemoveSupplierMutation,
            variables: {
              teamId: row.id,
              supplierId: item.id
            }
          }).catch(error => this.handleError(error))

          row.peopleLoading = false
          row.people.push(item)

          this.addSupplierToList(item.id)
        }
      }

      const index = this.clients.findIndex(client => client.id === row.id)
      this.clients[index] = row
      this.$emit('on-change', this.clients)

      localStorage.removeItem('dragged-item-list')
    },

    // Table Droppables
    async dropOnTable(e) {
      if (this.disable) return
      this.dropOverCounter = 0
      const draggedItem = localStorage.getItem('dragged-item-row')
      if (!draggedItem) return
      const item = JSON.parse(draggedItem)
      if (`${item.programData.programId}_${item.programData.scheduleType}` === `${this.programId}_${this.scheduleType}`) return

      let alreadyOnSchedule = false
      for (const peeps of item.data.people) {
        if (this.employeeIdUsed.includes(peeps.id)) {
          alreadyOnSchedule = true
        }
      }
      for (const trucks of item.data.trucks) {
        if (this.vehicleIdUsed.includes(trucks.id)) {
          alreadyOnSchedule = true
        }
      }

      const date = moment(this.currentDate).format('YYYY-MM-DD')
      if (date !== item.programData.date) {
        if (alreadyOnSchedule) return
      }

      const team = {
        id: null,
        dateStr: this.formatDate(this.currentDate),
        type: this.scheduleType,
        orderNumber: this.clients.length,
        program: { id: this.programId },
        time: '',
        employees: item.data.people.map(({ id }) => ({ id })),
        vehicles: item.data.trucks.map(({ id }) => ({ id }))
      }

      const data = await this.$apollo
        .mutate({
          mutation: saveTeamQuery,
          variables: { team }
        })
        .catch(error => {
          this.handleError(error)
        })

      eventBus.rowDropOnSameScheule(item)

      for (const employee of item.data.people) {
        this.insertIntoEmployeeIdUsed(employee.id)
      }
      for (const truck of item.data.trucks) {
        this.insertIntoVehicleIdUsed(truck.id)
      }

      if (this.dateToCompare?.date === this.currentDate) {
        this.reassignLocalStorageIdItems()
      }

      const newTeam = {
        id: data.data.saveTeam.id,
        ora: '',
        trucks: item.data.trucks,
        people: item.data.people,
        orderNumber: this.clients.length
      }

      const afterElement = this.getDragAfterElement(this.$refs[`table${this.randomIdSignature}`], e.clientY)

      if (afterElement) {
        this.indexToInsertInToTable = Number(afterElement.getAttribute('data-id'))
      } else {
        this.indexToInsertInToTable = null
      }
      if (this.indexToInsertInToTable || this.indexToInsertInToTable === 0) {
        this.clients.splice(this.indexToInsertInToTable, 0, newTeam)
        await this.changeRowOrder()
      } else {
        this.clients.push(newTeam)
        await this.changeRowOrder()
      }
      this.$emit('on-change', this.clients)
      localStorage.removeItem('dragged-item-row')
      this.dropOverCounter = 0
    },
    dragEnterTable() {
      this.dropOverCounter++
    },
    dragLeaveTable() {
      this.dropOverCounter--
    },
    dragStartTable() {
    },

    isMobileScreen() {
      return window.matchMedia('only screen and (max-width: 600px)').matches
    }
  },

  computed: {
    ...mapState({
      todaysDate: state => state.date.todaysDate,
      todaysDateTime: state => state.date.todaysDateTime,
      currentDate: state => state.date.currentDate,
      currentDateTime: state => state.date.currentDateTime,
      employeeIdUsed: state => state.employees.employeeIdUsed,
      supplierIdUsed: state => state.suppliers.supplierIdUsed,
      vehicleIdUsed: state => state.vehicles.vehicleIdUsed
    }),

    draggableRow() {
      return !(
        // not draggable when on mobile
        this.isMobileScreen()

        // not draggable when not in copy mode is disabled or the date is in the past
        || (!this.copyMode && (this.disable || this.currentDate < this.todaysDate))
      )
    },

    fixedProjectScheduleHeight() {
      return getFixedProjectScheduleHeight()
    }
  }
}
</script>

<style>
.overlay-drop {
  background: rgba(0, 0, 0, 0.1);
  border: 3px dashed rgb(169, 169, 169);
  /* z-index: 2; */
  width: 100%;
  height: 100%;
}

.hover:hover {
  background: rgba(0, 0, 0, 0.1);
}

.v-chip.v-size--x-small {
  border-radius: 16px !important;
  height: 20px !important;
  padding: 0 6px !important;
}

.truncate-chip {
  overflow: hidden;
  text-overflow: ellipsis;
}

.blue-border {
  border: 2px dashed dodgerblue;
}

.black-background {
  background: rgba(0, 0, 0, 0.2);
}

.scroll-bar::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  border-radius: 5px;
}

/* Track */
.scroll-bar::-webkit-scrollbar-track {
  background: #f6f8fa;
  border-radius: 5px;
}

/* Handle */
.scroll-bar::-webkit-scrollbar-thumb {
  background: #d0cfcf;
  border-radius: 5px;
}

/* Handle on hover */
.scroll-bar::-webkit-scrollbar-thumb:hover {
  background: #888;
  border-radius: 5px;
}

.overflow-table {
  overflow-y: scroll;
  overflow-x: hidden;
}

.overflow-hidden {
  overflow: hidden;
}

.fixed-table-height {
  height: 350px;
}

.dynamic-table-height {
  min-height: 350px;
}

@media only screen and (max-width: 600px) {
  /* used to check screen size */
}
</style>
