import apolloUtil from '@/service/ApolloUtil'

import employeeSuggestedVehiclesQuery from '@/service/employee/EmployeeSuggestedVehiclesQuery.gql'
import disableEmployeeMutation from '@/service/employee/DisableEmployeeMutation.gql'
import employeeQuery from '@/service/employee/EmployeeQuery.gql'

class EmployeeService {

  getEmployeeSuggestedVehicles(params) {
    return apolloUtil.query(employeeSuggestedVehiclesQuery, params)
      .then((response) => response?.data?.getEmployeeSuggestedVehicles)
  }

  disable(params) {
    return apolloUtil.mutate(disableEmployeeMutation, params)
  }

  search(params) {
    return apolloUtil.query(employeeQuery, params)
      .then((response) => response.data.employees)
  }

}

export default new EmployeeService()
