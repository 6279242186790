<template>
  <v-dialog :value="value" max-width="500px" persistent>
    <v-card>
      <v-card-title> {{ translations.cloneScheduleTitle }}</v-card-title>
      <v-card-text>
        <v-container>
          <section class="d-flex justify-space-between flex-column">
            <v-date-picker width="auto" tag="div" flat v-model="cloneDate" locale="it" range></v-date-picker>
          </section>
        </v-container>
      </v-card-text>
      <v-card-actions style="border-top: 1px solid rgba(125, 125, 125, 0.2)">
        <v-btn color="red darken-1" text @click="$emit('input', false)">
          {{ translations.close }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1 white--text" @click="submit" :disabled="isSubmitDisabled">
          {{ translations.submit }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { translations } from '../utils/common'
import { mapState } from 'vuex'

export default {
  props: {
    value: {
      type: Boolean,
      default: () => false
    },

    startValue: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      cloneDate: null
    }
  },

  created() {
    this.cloneDate = [this.startValue]
  },

  methods: {
    submit() {
      this.$emit('submit', {
        cloneDatesRange: this.getRangeDates
      })

      this.dialog = false

      this.$emit('input', false)
    }
  },

  computed: {
    translations: () => translations,

    ...mapState({
      todaysDate: state => state.date.todaysDate
    }),

    getRangeDates() {
      if (this.cloneDate.length === 1) {
        return [...this.cloneDate]
      }

      const sortedDates = [...this.cloneDate].sort()

      let datesRange = []
      let startDate = new Date(sortedDates.at(0))
      let endDate = new Date(sortedDates.at(sortedDates.length - 1))

      while (startDate <= endDate) {
        datesRange.push(startDate.toISOString().slice(0, 10))
        startDate.setDate(startDate.getDate() + 1)
      }

      return datesRange
    },

    isSubmitDisabled() {
      return this.cloneDate.length === 0 || this.getRangeDates[0] < this.todaysDate
    }
  }
}
</script>
