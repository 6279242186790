import Schedule from './Schedule'

export function createSchedulFromProgram(program) {
  const project = {
    id: program.id,
    names: program?.programProjects?.map(({ project }) => project.keyCode) || [],
    client: program.programProjects.map(({ project }) => project.clientCode) || [],
    conGlomerateDesc: program.asphaltConcrete || '',
    quintalsExpectedDesc: program.quantity || '',
    part: program.programProjects.map(a => a?.part || [])?.[0],
    projects: program?.programProjects?.map(({ id, project, part, main, orderNumber, allowance }) => ({
      id: id,
      main: main,
      projectId: project.id,
      name: project.keyCode,
      label: project.label,
      part: part?.id,
      parts: project?.parts,
      projectFilter: project.keyCode,
      projectSearchLoader: false,
      orderNumber: orderNumber,
      allowance: allowance
    }))
  }

  const teams = []

  program.teams.forEach(t => {
    teams.push({
      id: t.id,
      ora: t.time.length === 4 ? `0${t.time}` : t.time,
      trucks: t.vehicles.map(a => ({
        id: a.id,
        code: a.code || '',
        label: a.label || '',
        type: 'truck'
      })),
      people: [
        ...(t.employees?.map(e => ({ ...e, type: 'employee' })) || []),
        ...(t.suppliers?.map(s => ({
          ...s,
          name: s.description,
          type: 'supplier'
        })) || [])
      ],
      note: t.note,
      orderNumber: t.orderNumber,
      foremanEmployee: t.foremanEmployee,
      teamType: t.type
    })
  })

  return new Schedule(program.id, program.orderNumber, project, teams, true)
}
