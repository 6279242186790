<template>
  <v-card class="ma-2 pa-3 tom-card" style="cursor:default">
    <h3 class="main--text pb-1">{{ item.title }}</h3>

    <div v-for="member in item.members" :key="member.id" class="d-flex justify-space-between align-center">
      <p class="my-1">
        {{ member.employee.name }}
        <template v-if="member.dayPart">
          ({{ $store.getters.activityRequestDayPartsMap[member.dayPart] }})
        </template>
      </p>
    </div>
  </v-card>
</template>


<script>
export default {
  name: 'MemberCard',

  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>


<style>
.tom-card {
  font-size: 12px;
}
</style>
